









import {Component, Vue} from 'vue-property-decorator';
import {AuthService} from '@/auth/AuthService';
import AppScreen from '@/layout/AppScreen.vue';

@Component({
    components: {
        AppScreen,
    },
})
export default class LogoutScreen extends Vue {
    async mounted(): Promise<void> {
        const authService = await AuthService.getInstance();

        if (authService.getUser()) {
            authService.logout();
        } else {
            this.$router.push({name: 'postLogout'});
        }
    }
}
