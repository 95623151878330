












import {Component, Vue} from 'vue-property-decorator';
import {AuthService} from '@/auth/AuthService';

@Component
export default class PostLogoutScreen extends Vue {
    async mounted(): Promise<void> {
        const authService = await AuthService.getInstance();
        if (authService.getUser()) {
            this.$router.push({name: 'home'});
        }
    }

    async onLoginClick(): Promise<void> {
        (await AuthService.getInstance()).loginRedirect();
    }
}
